import { isSameIngredient, uuid } from '@/utils';
import { calculation, UiIngredient } from './state';
import { cloneDeep } from 'lodash-es';
import { calculationService } from '@/services/calculation-service';
import { CalculationProgressStage } from '@/types/enums';
import { PROGRESS_POLL_RATE } from '@/constants';
import { state } from '@/store';

export const solveCalculation = async (calcData: Array<UiIngredient[]>) => {
  calculation.id = undefined;
  calculation.progress = undefined;
  calculation.result = undefined;
  calculation.hasChanged = false;

  const response = await calculationService.solveCalculation({
    scenarios: calcData.map((scenario) => ({
      ingredients: scenario
        .filter(
          (ingredient): ingredient is Required<UiIngredient> =>
            ingredient.ingredientId !== undefined &&
            ingredient.ingredientKind !== undefined,
        )
        .map((ingredient) => ({
          ingredientId: ingredient.ingredientId,
          ingredientKind: ingredient.ingredientKind,
          amount: {
            value: ingredient.amount?.value ?? 0,
            unit: state.user.settings.defaults.unit,
          },
        })),
    })),
    temperature: state.calculation.temperature,
  });

  switch (response.status) {
    case 200:
    case 201: {
      if (response.ok) {
        calculation.calculating = true;
        calculation.id = response.data.calculationId;

        return await getProgressOnCalculation();
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('solveCalculation: Unhandled response', response);
      } else {
        console.error('solveCalculation: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const getProgressOnCalculation = async () => {
  if (!calculation.id) {
    return { success: false, error: 'No calculation' };
  }

  if (!calculation.calculating) {
    return { success: false, error: 'No longer calculating (did you cancel?)' };
  }

  const response = await calculationService.getProgress(calculation.id);

  switch (response.status) {
    case 200: {
      if (response.ok) {
        calculation.progress = response.data;

        switch (calculation.progress.stage) {
          case CalculationProgressStage.FINISHED: {
            return await getResultsById(calculation.id);
          }
          case CalculationProgressStage.WAITING:
          case CalculationProgressStage.RUNNING: {
            return new Promise(
              (
                resolve: (value: { success: boolean; error?: string }) => void,
              ) => {
                setTimeout(
                  async () => resolve(await getProgressOnCalculation()),
                  PROGRESS_POLL_RATE,
                );
              },
            );
          }
        }
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getProgressOnCalculation: Unhandled response', response);
      } else {
        console.error('getProgressOnCalculation: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const cancelCalculation = async () => {
  if (!calculation.id) {
    return { success: false, error: 'No calculation' };
  }

  const response = await calculationService.cancelCalculation(calculation.id);

  switch (response.status) {
    case 200: {
      calculation.calculating = false;
      calculation.progress = undefined;

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getResults: Unhandled response', response);
      } else {
        console.error('getResults: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const getResultsById = async (
  calculationId: string,
  type: 'json' | 'excel' = 'json',
) => {
  const response = await calculationService.resultCalculation(
    calculationId,
    type,
  );

  switch (response.status) {
    case 200: {
      if (response.ok) {
        calculation.calculating = false;
        calculation.result = response.data;

        return { success: true, data: response.data };
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn('getResultsById: Unhandled response', response);
      } else {
        console.error('getResultsById: Unhandled error', response);
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const getFormulationFromCalculation = async (calculationId: string) => {
  const response = await calculationService.getFormulationFromCalculation(
    calculationId,
  );

  switch (response.status) {
    case 200: {
      if (response.ok) {
        calculation.formulationOfResult = response.data;
      }

      return { success: true };
    }
    case 500: {
      return { success: false, error: 'Internal server error' };
    }
    default: {
      if (response.ok) {
        console.warn(
          'getFormulationFromCalculation: Unhandled response',
          response,
        );
      } else {
        console.error(
          'getFormulationFromCalculation: Unhandled error',
          response,
        );
      }

      return { success: false, error: 'Something went wrong...' };
    }
  }
};

export const addIngredient = (scenarioIndex: number) => {
  if (!calculation.scenarios[scenarioIndex]) {
    return;
  }

  calculation.hasChanged = true;

  calculation.scenarios[scenarioIndex] = [
    ...calculation.scenarios[scenarioIndex],
    {
      uid: uuid(),
      ingredientId: undefined,
      ingredientKind: undefined,
      amount: {
        value: 0,
      },
    },
  ];
};

export const updateIngredient = (
  scenarioIndex: number,
  ingredient: UiIngredient,
) => {
  if (!calculation.scenarios[scenarioIndex]) {
    return;
  }

  calculation.hasChanged = true;

  const ingredientIndex = calculation.scenarios[scenarioIndex].findIndex(
    (_ingredient) => isSameIngredient(_ingredient, ingredient),
  );

  calculation.scenarios[scenarioIndex][ingredientIndex] = {
    ...ingredient,
  };
};

export const removeIngredient = (
  scenarioIndex: number,
  ingredient: UiIngredient,
) => {
  if (!calculation.scenarios[scenarioIndex]) {
    return;
  }

  calculation.hasChanged = true;

  calculation.scenarios[scenarioIndex] = [
    ...calculation.scenarios[scenarioIndex].filter(
      (_ingredient) => _ingredient.uid !== ingredient.uid,
    ),
  ];
};

export const addScenario = () => {
  calculation.hasChanged = true;

  calculation.scenarios = [
    ...calculation.scenarios,
    [
      {
        uid: uuid(),
        ingredientId: undefined,
        ingredientKind: undefined,
        amount: {
          value: 0,
        },
      },
    ],
  ];
};

export const resetScenarios = () => {
  calculation.hasChanged = true;

  calculation.scenarios = [
    [
      {
        uid: uuid(),
        ingredientId: undefined,
        ingredientKind: undefined,
        amount: {
          value: 0,
        },
      },
    ],
    [
      {
        uid: uuid(),
        ingredientId: undefined,
        ingredientKind: undefined,
        amount: {
          value: 0,
        },
      },
    ],
  ];
};

export const resetTemperature = () => {
  calculation.hasChanged = true;

  calculation.temperature = {
    ...state.user.settings.defaults.temperature,
  };
};

export const updateScenario = (
  scenarioIndex: number,
  scenario: UiIngredient[],
) => {
  if (!calculation.scenarios[scenarioIndex]) {
    return;
  }

  calculation.hasChanged = true;

  calculation.scenarios[scenarioIndex] = [...scenario];
};

export const removeScenario = (scenarioIndex: number) => {
  if (!calculation.scenarios[scenarioIndex]) {
    return;
  }

  calculation.hasChanged = true;

  calculation.scenarios = [
    ...calculation.scenarios.filter((_, i) => i !== scenarioIndex),
  ];
};

export const duplicateScenario = (scenarioIndex: number) => {
  if (!calculation.scenarios[scenarioIndex]) {
    return;
  }

  calculation.hasChanged = true;

  calculation.scenarios = [
    ...calculation.scenarios,
    cloneDeep(calculation.scenarios[scenarioIndex]),
  ];
};
