import {
  CalculationIdDto,
  CalculationProgressDto,
  CreateSscFormulationDto,
  SscCalculationResultsDto,
} from '@/types/calculation';
import { BaseService } from './base-service';

class CalculationService extends BaseService {
  async solveCalculation(calculation: CreateSscFormulationDto) {
    return await this.post<CalculationIdDto>('ssc/solve', {
      body: JSON.stringify(calculation),
    });
  }

  async getProgress(calculationId: string) {
    return await this.get<CalculationProgressDto>(
      `ssc/${calculationId}/progress`,
    );
  }

  async cancelCalculation(calculationId: string) {
    return await this.put<SscCalculationResultsDto>(
      `ssc/${calculationId}/cancel`,
    );
  }

  async resultCalculation(
    calculationId: string,
    type: 'json' | 'excel' = 'json',
  ) {
    const contentTypeMap = {
      json: 'application/json',
      excel:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    };

    const contentType = contentTypeMap[type];

    return await this.get<SscCalculationResultsDto>(
      `ssc/${calculationId}/results`,
      {
        headers: {
          'Content-Type': contentType,
        },
      },
    );
  }

  async getFormulationFromCalculation(calculationId: string) {
    return await this.get<CreateSscFormulationDto>(
      `ssc/${calculationId}/formulation`,
    );
  }
}

export const calculationService = new CalculationService();
